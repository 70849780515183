declare var fx: any;

namespace Filters {
    export class TVGlassFilter {
        private _renderCanvas: HTMLCanvasElement;

        constructor() {
            this._renderCanvas = fx.canvas();
        }

        public chainFn: Engine.RendererChainFn = (canvas) => {
            if (this._renderCanvas.width !== canvas.width || this._renderCanvas.height !== canvas.height) {
                this._renderCanvas.width = canvas.width;
                this._renderCanvas.height = canvas.height;
            }

            let texture = (<any>this._renderCanvas).texture(canvas);
            (<any>this._renderCanvas).draw(texture)
                .bulgePinch(canvas.width/2, canvas.height/2, canvas.width*0.75, 0.02)
                .vignette(0.25, 0.74)
                .update();

            return this._renderCanvas;
        };
    }
}