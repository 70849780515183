namespace Narration {
    export abstract class Narratable {
        abstract render: Engine.RendererChainFn;
        abstract onKeyCode(keyCode: number): void;

        private _completeListeners: ((nextNarratable: Narratable) => void)[] = [];
        private _completed = false;
        
        public addCompleteListener(callback: (nextNarratable: Narratable) => void) {
            this._completeListeners.push(callback);
        }

        protected completeNarration(nextNarratable: Narratable) {
            if (!this._completed) {
                this._completed = true;
                this._completeListeners.forEach(x => x(nextNarratable));
            }
        }
    }

    export class Narrator {
        private _currentlyNarrating: Narratable;

        constructor(firstNarratable: Narratable) {
            window.addEventListener("keydown", (event) => {
                this._currentlyNarrating.onKeyCode(event.keyCode);
            });
            this.startNarration(firstNarratable);
        }

        private startNarration(newNarratable: Narratable) {
            newNarratable.addCompleteListener(this.startNarration.bind(this));
            this._currentlyNarrating = newNarratable;
        }

        public chainFn: Engine.RendererChainFn = (canvas) => {
            return this._currentlyNarrating.render(canvas);
        };
    }
}