/// <reference path="../narrator.ts"/>

namespace Story {
    export class PuzzleGame extends Narration.Narratable {
        private _sequence = [Puzzles.Level1, Puzzles.Level2, Puzzles.Level3, Puzzles.Level4, Puzzles.Level5];
        private _currentLevel = 0;
        private _isInverted = false;
        private _revertsLeft = 1;
        private _currentPuzzle: Puzzles.Puzzle = null;
        private _puzzleAfterMove: Puzzles.Puzzle = null;
        private _isMovingRem: {x: number, y: number} = null;
        private _isMovingDone: {x: number, y: number} = null;
        private _willBeCompleted = false;
        private _completionLock = 0;
        private _isRestarting = false;
        private _isDying = false;

        static gridSize = 60;
        static numInverts = [1, 2, 2, 1, 5];

        onKeyCode(keyCode: number): void {
            if (this._isMovingRem || !this._currentPuzzle || this._completionLock) return;
            switch(keyCode) {
                case 37:
                    this.handleMovement('L'); break;
                case 38:
                    this.handleMovement('U'); break;
                case 39:
                    this.handleMovement('R'); break;
                case 40:
                    this.handleMovement('D'); break;
                case 27:
                    this.triggerRestart(); break;
                case 32:
                    this.invertLevel(); break;
            }
        }

        invertLevel() {
            if (this._revertsLeft) {
                this._isInverted = !this._isInverted;
                this._revertsLeft--;
            }
        }

        triggerRestart() {
            this._isRestarting = true;
            this._completionLock = 100;
        }

        restartLevel() {
            this._currentPuzzle = this._sequence[this._currentLevel];
            this._revertsLeft = PuzzleGame.numInverts[this._currentLevel] || 1;
            this._isInverted = false;
            this._isMovingDone = null;
            this._isMovingRem = null;
            this._isRestarting = false;
            this._isDying = false;
            this._completionLock = 0;
        }

        completeLevel() {
            if (this._isRestarting) {
                return this.restartLevel();
            }
            this._willBeCompleted = false;
            this._completionLock = 0;
            if (this._sequence.length > this._currentLevel + 1) {
                this._currentLevel++;
                this.restartLevel();
            } else {
                this.completeNarration(new Story.LogoScreen());
            }
        }

        handleMovement(direction: 'U' | 'D' | 'L' | 'R') {
            let wall = this._isInverted ? ' ' : '#';
            let hiddenWall = this._isInverted ? 'D' : 'D';

            // Find current position
            let curCoords: {x: number, y: number} = null;
            this._currentPuzzle.forEach((row, y) => {
                if (curCoords) return;
                row.forEach((cell, x) => {
                    if (curCoords) return;
                    if (cell === 'S') {
                        curCoords = {x: x, y: y};
                    }
                });
            });

            // Find next valid destination
            let y = curCoords.y;
            let x = curCoords.x;
            let limitCondition = () => {
                if (direction === 'U' || direction === 'D') {
                    return y < this._currentPuzzle.length && y >= 0;
                } else {
                    return x < this._currentPuzzle[0].length && x >= 0;
                }
            };
            let increment = () => {
                switch (direction) {
                    case 'U': y--; return;
                    case 'D': y++; return;
                    case 'L': x--; return;
                    case 'R': x++; return;
                }
            };
            for (; limitCondition(); increment()) {
                let cell = this._currentPuzzle[y][x];
                if (cell === wall || cell === hiddenWall) { 
                    break; 
                } else if (cell === 'G' || cell === 'H') {
                    this._willBeCompleted = true;
                    break;
                } else if (cell === '@') {
                    this._isRestarting = true;
                    this._isDying = true;
                    break;
                }
            }
            // Loop ends one step too far, bring it back
            // Don't bring it back for restarts so the character "dies" in the block
            if (!this._isRestarting) {
                switch (direction) {
                    case 'U': y++; break;
                    case 'D': y--; break;
                    case 'L': x++; break;
                    case 'R': x--; break;
                }
            }
            let safeY = (y: number) => Math.min(Math.max(0, y), this._currentPuzzle.length);
            let safeX = (x: number) => Math.min(Math.max(0, x), this._currentPuzzle[0].length);
            y = safeY(y);
            x = safeX(x);

            // Convert to display units and save
            this._isMovingRem = {
                x: (curCoords.x - x) * PuzzleGame.gridSize, 
                y: (curCoords.y - y) * PuzzleGame.gridSize};
            this._isMovingDone = {x: 0, y: 0};

            // Prepare future puzzle state
            this._puzzleAfterMove = JSON.parse(JSON.stringify(this._currentPuzzle));
            let origCell = this._sequence[this._currentLevel][curCoords.y][curCoords.x];
            this._puzzleAfterMove[curCoords.y][curCoords.x] = origCell !== 'S' ? origCell : !this._isInverted ? ' ' : '#';
            this._puzzleAfterMove[y][x] = 'S';
        }

        getMovementOffset(): {x: number, y: number} {
            let offsetX = 0, offsetY = 0;
            if (this._isMovingRem) {
                offsetX = this._isMovingDone.x, offsetY = this._isMovingDone.y;
                let x = 0, y = 0;
                if (this._isMovingRem.x >= 0) {
                    x += Math.min(10, this._isMovingRem.x);
                } else {
                    x += Math.max(-10, this._isMovingRem.x);
                }
                
                if (this._isMovingRem.y >= 0) {
                    y += Math.min(10, this._isMovingRem.y);
                } else {
                    y += Math.max(-10, this._isMovingRem.y);
                }

                this._isMovingRem = {x: this._isMovingRem.x - x, y: this._isMovingRem.y - y};

                offsetX -= x;
                offsetY -= y;

                this._isMovingDone = {x: offsetX, y: offsetY};

                if (this._isMovingRem.x === 0 && this._isMovingRem.y == 0) {
                    if (!this._isRestarting) {
                        this._isMovingRem = null;
                        this._currentPuzzle = this._puzzleAfterMove;
                        this._puzzleAfterMove = null;
                        this._isMovingDone = null;
                    } else if (!this._completionLock) {
                        this.triggerRestart();
                    }
                }
            } else {
                if (this._willBeCompleted && !this._completionLock) {
                    this._completionLock = 100;
                }
            }
            return {x: offsetX, y: offsetY};
        }

        getCellColor(cell: Puzzles.PuzzleCell) {
            switch (cell) {
                case 'G':
                case '#':
                    return this._isInverted ? null : "#161715";
                case ' ':
                    return this._isInverted ? "#161715" : null;
                case 'D':
                    return "#161715";
                case 'L':
                    return null;
                default:
                    return null;
            }
        }

        jitter(base: number) {
            return (Math.random() * 2 - 1) + base;
        }

        render: Engine.RendererChainFn = (canvas) => {
            let ctx = canvas.getContext("2d");

            // -- Draw puzzle --
            if (!this._currentPuzzle) {
                this._currentPuzzle = this._sequence[this._currentLevel];
            }
            let level = this._currentPuzzle;

            // Background
            ctx.fillStyle = "#B0B5B4";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            // Walls and elements
            level.forEach((row, y) => {
                let curY = y * PuzzleGame.gridSize;
                row.forEach((cell, x) => {
                    let color = this.getCellColor(cell);
                    let curX = x * PuzzleGame.gridSize;

                    if (color) {
                        ctx.fillStyle = color;
                        ctx.fillRect(curX, curY, PuzzleGame.gridSize, PuzzleGame.gridSize);
                    }

                    if (cell === 'S') {
                        let offset = this.getMovementOffset();
                        ctx.drawImage(Engine.AssetManager.GetAsset("player"), 
                            this.jitter(curX) + offset.x, this.jitter(curY) + offset.y + 4);
                    } else if (cell === 'G' || cell === 'H') {
                        ctx.drawImage(Engine.AssetManager.GetAsset("goal"), 
                            this.jitter(curX), this.jitter(curY) + 4);
                    } else if (cell === '@') {
                        ctx.drawImage(Engine.AssetManager.GetAsset("impenetrable"), 
                            this.jitter(curX), this.jitter(curY));
                    }
                });
            });

            if (this._completionLock > 0) {
                this._completionLock -= 5;
                if (!this._isDying) {
                    ctx.fillStyle = `rgba(0,0,0,${(100 -this._completionLock) / 100.0})`;
                } else {
                    ctx.fillStyle = `rgba(125,0,0,${(100 -this._completionLock) / 100.0})`;
                }
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                if (this._completionLock === 0) {
                    this.completeLevel();
                }
            }

            // -- Draw HUD --
            ctx.fillStyle = "#3d0000";
            ctx.fillRect(0, 540, 800, 34);
            ctx.fillStyle = "#C7D2CF";
            ctx.font = "20px monospace";
            ctx.fillText(`[ARROWS]: MOVE   [SPACE]: INVERT (${this._revertsLeft} LEFT)   [ESC]: RESTART`, 40, 562);

            return canvas;
        }
    }
}