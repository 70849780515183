/// <reference path="../narrator.ts"/>

namespace Story {
    export class LogoScreen extends Narration.Narratable {
        private _stage = 0;
        private _offset = 120;

        onKeyCode(keyCode: number): void {
            if (keyCode === 32 && this._stage === 0) {
                this._stage = 1;
                setTimeout(() => this._stage = 2, 700);
                setTimeout(() => this._stage = 3, 1200);
                setTimeout(() => this.completeNarration(new AboutScreen()), 1500);
            }
        }

        render: Engine.RendererChainFn = (canvas) => {
            let ctx = canvas.getContext("2d");

            if (this._stage == 0) {
                ctx.fillStyle = "#161715";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
                
                ctx.fillStyle = "#C7D2CF";
                ctx.font = "48px monospace";
                ctx.fillText("WRITEINVERSE", 400, 200);

                ctx.font = "20px monospace";
                ctx.fillText("PRESS [SPACE] TO CONTINUE", 100, 500);
            } else if (this._stage <= 2) {
                ctx.fillStyle =  this._stage == 1 ? "#C7D2CF" : "#161715";
                ctx.fillRect(0, 0, canvas.width, canvas.height);

                ctx.fillStyle = this._stage == 2 ? "#C7D2CF" : "#161715";
                ctx.font = "48px monospace";
                
                if (this._stage === 2) {
                    this._offset += Math.random() * 2 - 1;
                }
                ctx.fillText(this._stage == 1 ? "INVERSE" : "ESREV", 544 + (this._stage == 2 ? this._offset : 0), 200);
            } else {
                ctx.fillStyle = "#161715";
                ctx.fillRect(0, 0, canvas.width, canvas.height);
            }
            return canvas;
        }
    }
}