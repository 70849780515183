namespace Puzzles {
    export type PuzzleCell = '#' | ' ' | 'S' | 'G' | 'H' | '@' | 'D' | 'L';
    export type Puzzle = PuzzleCell[][];

    export var Level1: Puzzle = [
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#',' ',' ',' ',' ',' ',' ',' ',' ',' ','#','#','#','#'],
        ['#','S',' ',' ',' ',' ',' ',' ',' ',' ','#','#','G','#'],
        ['#',' ',' ',' ',' ',' ',' ',' ',' ',' ','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
    ];

    export var Level2: Puzzle = [
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#',' ',' ',' ',' ',' ','#','#','#',' ',' ',' ',' ','#'],
        ['#','S',' ',' ',' ',' ','#','#','#',' ',' ',' ','H','#'],
        ['#',' ',' ',' ',' ',' ','#','#','#',' ',' ',' ',' ','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
    ];

    export var Level3: Puzzle = [
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#',' ',' ',' ',' ',' ','#','#','#',' ',' ',' ',' ','#'],
        ['#','S',' ',' ',' ',' ','@','@','@',' ',' ',' ','H','#'],
        ['#',' ',' ',' ',' ',' ','#','#','#',' ',' ',' ',' ','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
    ];

    export var Level4: Puzzle = [
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','D','D','D','D','D','D','D','D','D','D','D','D','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','D','#'],
        ['#','S','@',' ',' ',' ',' ',' ',' ',' ','L','L','L','#'],
        ['#','@','@',' ',' ',' ',' ',' ',' ',' ','L','H','L','#'],
        ['#',' ',' ',' ',' ',' ',' ',' ',' ',' ','L','L','L','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
        ['#','#','#','#','#','#','#','#','#','#','#','#','#','#'],
    ];

    export var Level5: Puzzle = [
        ['#','#','#','#','#','#','#','G','#','#','#','#','#','#'],
        ['#','#','#','D','D','#','D',' ','D','D','D','D','#','#'],
        ['#','#','#','D','#','L','#','#','D','L','#','D','#','#'],
        ['#','#','#','D','#',' ','D',' ','L',' ','#','D','#','#'],
        ['#','#','@','@','#',' ','D','D','#',' ','#','@','@','#'],
        ['#','#','@','@','#',' ','#','S','#',' ','#','@','@','#'],
        ['#','#','@','@','#',' ','#','D','D',' ','#','@','@','#'],
        ['#','#','#','D','#',' ','L',' ','D',' ','#','D','#','#'],
        ['#','#','#','D','#','#','D','#','#','#','#','D','#','#'],
        ['#','#','#','D','D','D','D','D','D','D','D','D','#','#'],
    ];
}