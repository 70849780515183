/// <reference path="../narrator.ts"/>

namespace Story {
    export class LoadingScreen extends Narration.Narratable {
        constructor() {
            super();
            this.checkLoaded();
        }

        onKeyCode(keyCode: number): void {
            return;
        }

        checkLoaded() {
            if (Engine.AssetManager.GetLoadPercentage() === 1) {
                this.completeNarration(new Story.LogoScreen());
            } else {
                setTimeout(this.checkLoaded.bind(this), 200);
            }
        }

        render: Engine.RendererChainFn = (canvas) => {
            let ctx = canvas.getContext("2d");

            ctx.fillStyle = "#161715";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.fillStyle = "#C7D2CF";
            ctx.font = "30px monospace";
            ctx.fillText(`${Engine.AssetManager.GetLoadPercentage() * 100}% LOADED...`, 20, 50);

            return canvas;
        }
    }
}