/// <reference path="../narrator.ts"/>

namespace Story {
    export class AboutScreen extends Narration.Narratable {
        private _stage = 0;


        onKeyCode(keyCode: number): void {
            if (keyCode === 32) {
                this.completeNarration(new Story.PuzzleGame());
            }
        }

        jitter(base: number) {
            return (Math.random() * 6 - 3) + base;
        }

        render: Engine.RendererChainFn = (canvas) => {
            let ctx = canvas.getContext("2d");

            ctx.fillStyle = "#3d0000";
            ctx.fillRect(0, 0, canvas.width, canvas.height);

            ctx.drawImage(Engine.AssetManager.GetAsset("enemy"), this.jitter(232), this.jitter(27));

            ctx.fillStyle = "#C7D2CF";

            ctx.font = "24px monospace";
            ctx.fillText("YOU ARE TRAPPED IN MY WORLD", 205, 362);

            ctx.font = "36px monospace";
            ctx.fillText("GAIN FREEDOM THROUGH PUZZLES", 108, 417);

            ctx.font = "24px monospace";
            ctx.fillText("PRESS [SPACE] TO CONTINUE", 219, 486);

            return canvas;
        }
    }
}