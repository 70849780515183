function Main() {
    // Begin asset loading
    Engine.AssetManager.RegisterAssets([
        "enemy", "player", "goal", "impenetrable"
    ]);

    // Initialize game engine
    let renderer = new Engine.Renderer();
    let narrator = new Narration.Narrator(new Story.LoadingScreen());

    // Prepare display filters
    let scanlineFilter = new Filters.ScanlineFilter();
    let tvglassFilter = new Filters.TVGlassFilter();

    // Start it all up
    Helpers.PowerOnSequence(scanlineFilter);

    renderer.chain(Helpers.SetResolutionChainFn);
    renderer.chain(narrator.chainFn);
    renderer.chain(scanlineFilter.chainFn);
    renderer.chain(tvglassFilter.chainFn);
}

window.onload = Main;