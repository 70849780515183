namespace Engine {
    export type RendererChainFn = (canvas: HTMLCanvasElement) => HTMLCanvasElement;
    
    export class Renderer {
        public width: number;
        public height: number;

        private _backCanvas: HTMLCanvasElement;
        private _frontCanvas: HTMLCanvasElement;
        private _frontCanvasCtx: CanvasRenderingContext2D;
        private _chain: RendererChainFn[];
        
        constructor() { 
            this._frontCanvas = document.querySelector("canvas.display");
            this._backCanvas = document.createElement("canvas");
            this._frontCanvasCtx = this._frontCanvas.getContext("2d");
            this._chain = [];
            this.recalculateDimensions();
            window.addEventListener("resize", () => this.recalculateDimensions());
            this.loop();
        }

        public chain(chainFn: RendererChainFn) {
            this._chain.push(chainFn);
        }

        private recalculateDimensions() {
            this.width = this._frontCanvas.width = this._backCanvas.width = window.innerWidth;
            this.height = this._frontCanvas.height = this._backCanvas.height = window.innerHeight;
        }

        private loop() {
            let lastCanvas = this._chain.reduce(
                (prevCanvas, chainFn) => chainFn(prevCanvas), this._backCanvas);
            this._frontCanvasCtx.drawImage(lastCanvas, 0, 0, this.width, this.height);
            window.requestAnimationFrame(this.loop.bind(this));
        }
    }
}