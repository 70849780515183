declare var Seriously: any;

namespace Filters {
    export class ScanlineFilter {
        private _seriously: any;
        private _renderCanvas: HTMLCanvasElement;
        private _scanlines: any;
        private _tvglitch: any;
        private _display: any;

        public intensity: number = 1;
        public speed: number = 1;

        constructor() {
            this._seriously = new Seriously();
            this._renderCanvas = this._renderCanvas || document.createElement("canvas");
            this._display = this._seriously.target(this._renderCanvas);
            this._scanlines = this._seriously.effect("scanlines");
            this._tvglitch = this._seriously.effect("tvglitch");
        }

        public chainFn: Engine.RendererChainFn = (canvas) => {
            if (this._renderCanvas.width !== canvas.width || this._renderCanvas.height !== canvas.height) {
                this._renderCanvas.width = canvas.width;
                this._renderCanvas.height = canvas.height;
                this.constructor();
            }

            this._scanlines.source = canvas;
            this._scanlines.intensity = 0.2 * this.intensity;
            this._scanlines.size = 0.5;
            this._tvglitch.source = this._scanlines;
            this._tvglitch.distortion = 0.001 * this.intensity;
            this._tvglitch.verticalSync = 0;
            this._tvglitch.scanlines = 0.22;
            this._tvglitch.lineSync = 0.03;
            this._tvglitch.frameSharpness = 10.67;
            this._tvglitch.frameLimit = 0.3644;
            this._tvglitch.bars = 0.15 * this.intensity;
            this._display.source = this._tvglitch;

            this._tvglitch.time += 0.01 * this.speed;
            

            this._scanlines.source.update();
            this._seriously.render();
            return this._renderCanvas;
        };
    }
}