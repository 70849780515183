namespace Engine {
    export class AssetManager {
        private static _assets: {[name: string]: HTMLImageElement} = {};
        private static _loadCount = 0;

        static RegisterAssets(assets: string[]) {
            assets.forEach(asset => {
                let img = new Image();
                img.src = `images/${asset}.png`;
                img.onload = () => AssetManager._loadCount++;
                AssetManager._assets[asset] = img;
            });
        }

        static GetAsset(asset: string) {
            return AssetManager._assets[asset];
        }

        static GetLoadPercentage(): number {
            return AssetManager._loadCount / Object.keys(AssetManager._assets).length;
        }
    }
}