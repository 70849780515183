namespace Helpers {
    export let SetResolutionChainFn: Engine.RendererChainFn = (canvas) => {
        canvas.width = 800;
        canvas.height = 600;
        return canvas;
    };

    export let PowerOnSequence = (scanlineFilter: Filters.ScanlineFilter) => {
        scanlineFilter.intensity = 10;
        scanlineFilter.speed = 5;

        setTimeout(() => {
            scanlineFilter.intensity = 8;
            scanlineFilter.speed = 4;
        }, 200);

        setTimeout(() => {
            scanlineFilter.intensity = 6;
            scanlineFilter.speed = 4;
        }, 400);

        setTimeout(() => {
            scanlineFilter.intensity = 4;
            scanlineFilter.speed = 4;
        }, 600);

        setTimeout(() => {
            scanlineFilter.intensity = 3;
            scanlineFilter.speed = 3;
        }, 800);

        setTimeout(() => {
            scanlineFilter.intensity = 1;
            scanlineFilter.speed = 2;
        }, 1000);

        setTimeout(() => {
            scanlineFilter.intensity = 1;
            scanlineFilter.speed = 1;
        }, 1200);

        setTimeout(() => PowerOnSequence(scanlineFilter), Math.random() * 20000 + 5000);
    };
}